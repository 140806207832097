@custom-media --mediaDesktop (max-width: 2080px);
@custom-media --mediaLaptop (max-width: 1680px);
@custom-media --mediaTablet (max-width: 1024px);
@custom-media --mediaMobile (max-width: 696px);
@custom-media --mediaMobileS (max-width: 400px);
@custom-media --mediaUseMotion (prefers-reduced-motion: no-preference);
@custom-media --mediaReduceMotion (prefers-reduced-motion: reduce);

body {
  font-family: var(--fontStack);
  font-weight: var(--fontWeightRegular);
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  background: rgb(var(--rgbBackground));
  color: var(--colorTextBody);
  border: 0;
  margin: 0;
  width: 100vw;
  overflow-x: hidden;
}

::selection {
  background: rgb(var(--rgbAccent));
  color: rgb(var(--rgbBlack));
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.app {
  width: 100%;
  position: relative;
  background-image: rgb(var(--rgbBackground));
  transition: background var(--durationM) ease;
  outline: none;
  display: grid;
  grid-template: 100% / 100%;
}

.app__page {
  opacity: 0;
  grid-area: 1 / 1;
  transition: opacity var(--durationS) ease;
  min-height: 100vh;
}

.app__page--exiting,
.app__page--entering {
  opacity: 0;
}

.app__page--entered {
  transition-duration: var(--durationL);
  transition-delay: var(--durationXS);
  opacity: 1;
}

.skip-to-main {
  color: rgb(var(--rgbBackground));
  z-index: 128;

  &:focus {
    padding: var(--spaceS) var(--spaceM);
    position: fixed;
    top: var(--spaceM);
    left: var(--spaceM);
    text-decoration: none;
    font-weight: var(--fontWeightMedium);
    line-height: 1;
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
    outline: none;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgb(var(--rgbPrimary));
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
    z-index: -1;
  }
}

.the-arrow {
  width: 64px;
  transition: all 0.2s;
}
.the-arrow.-left {
  position: absolute;
  top: 60%;
  left: 0;
}
.the-arrow.-left > .shaft {
  width: 0;
  background-color: #999;
}
.the-arrow.-left > .shaft:before,
.the-arrow.-left > .shaft:after {
  width: 0;
  background-color: #999;
}
.the-arrow.-left > .shaft:before {
  transform: rotate(0);
}
.the-arrow.-left > .shaft:after {
  transform: rotate(0);
}
.the-arrow.-right {
  top: 3px;
}
.the-arrow.-right > .shaft {
  width: 64px;
  transition-delay: 0.2s;
}
.the-arrow.-right > .shaft:before,
.the-arrow.-right > .shaft:after {
  width: 8px;
  transition-delay: 0.3s;
  transition: all 0.5s;
}
.the-arrow.-right > .shaft:before {
  transform: rotate(40deg);
}
.the-arrow.-right > .shaft:after {
  transform: rotate(-40deg);
}
.the-arrow > .shaft {
  background-color: #999;
  display: block;
  height: 1px;
  position: relative;
  transition: all 0.2s;
  transition-delay: 0;
  will-change: transform;
}
.the-arrow > .shaft:before,
.the-arrow > .shaft:after {
  background-color: #999;
  content: '';
  display: block;
  height: 1px;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.2s;
  transition-delay: 0;
}
.the-arrow > .shaft:before {
  transform-origin: top right;
}
.the-arrow > .shaft:after {
  transform-origin: bottom right;
}
.animated-arrow-wrapper {
  display: inline-block;
  color: #999;
  font-size: 1.25em;
  font-style: italic;
  text-decoration: none;
  position: relative;
  transition: all 0.2s;
  margin-top: 15px;
}
.italic{
  font-style: italic;

}

.animated-arrow:hover > .nav-link > .animated-arrow-wrapper > .the-arrow.-left > .shaft {
  width: 64px;
  transition-delay: 0.1s;
  background-color: #999;
}
.animated-arrow:hover
  > .nav-link
  > .animated-arrow-wrapper
  > .the-arrow.-left
  > .shaft:before,
.animated-arrow:hover
  > .nav-link
  > .animated-arrow-wrapper
  > .the-arrow.-left
  > .shaft:after {
  width: 8px;
  transition-delay: 0.1s;
  background-color: #999;
}
.animated-arrow:hover
  > .nav-link
  > .animated-arrow-wrapper
  > .the-arrow.-left
  > .shaft:before {
  transform: rotate(40deg);
}
.animated-arrow:hover
  > .nav-link
  > .animated-arrow-wrapper
  > .the-arrow.-left
  > .shaft:after {
  transform: rotate(-40deg);
}
.animated-arrow:hover > .nav-link > .animated-arrow-wrapper > .main {
  transform: translateX(80px);
}
.animated-arrow:hover
  > .nav-link
  > .animated-arrow-wrapper
  > .main
  > .the-arrow.-right
  > .shaft {
  width: 0;
  transform: translateX(200%);
  transition-delay: 0;
}
.animated-arrow:hover
  > .nav-link
  > .animated-arrow-wrapper
  > .main
  > .the-arrow.-right
  > .shaft:before,
.animated-arrow:hover
  > .nav-link
  > .animated-arrow-wrapper
  > .main
  > .the-arrow.-right
  > .shaft:after {
  width: 0;
  transition-delay: 0;
  transition: all 0.1s;
}
.animated-arrow:hover
  > .animated-arrow-wrapper
  > .main
  > .the-arrow.-right
  > .shaft:before {
  transform: rotate(0);
}
.animated-arrow:hover
  > .animated-arrow-wrapper
  > .main
  > .the-arrow.-right
  > .shaft:after {
  transform: rotate(0);
}
.animated-arrow > .nav-link > .animated-arrow-wrapper > .main {
  display: flex;
  align-items: center;
  transition: all 0.2s;
}
.animated-arrow > .nav-link > .animated-arrow-wrapper > .main > .text {
  margin: 0 16px 0 0;
  line-height: 1;
}
.animated-arrow > .nav-link > .animated-arrow-wrapper > .main > .the-arrow {
  position: relative;
}
.animated-arrow{
  width: 100%;
}

.mb-2{
  margin-bottom: 20px;
}

.mr-3 {
  margin-right: 30px;
}

.mt-3 {
  margin-top: 30px;
}
.mb-3 {
  margin-bottom: 30px;
}
.mt-1{
  margin-top: 10px;
}